import * as React from 'react';
import Loader from './Shared/Loader';

export function TestingSuite(props) {
  return (
    <div>
      <Loader />
    </div>
  );
}
